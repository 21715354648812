<template>
  <slot />
</template>

<script>
export default {
  props: {
    action: String,
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    async onConfirm() {
      try {
        this.loading = true

        await this.$http.delete(`/publications/${ this.$store.state.publication.data.id }/actions/${ this.action }`)

        this.$message.success('Call-to-action has been deleted from the publication.')
        this.$emit('success')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.$emit('cancel')
        this.loading = false
      }
    },
  },

  watch: {
    visible(value) {
      if (value === true) {
        this.$confirm({
          title: 'Delete Call-to-Action',
          content: 'You are permanently deleting this call-to-action from your publication. The action cannot be undone.',
          okText: 'Delete',
          okType: 'danger',
          confirmLoading: this.loading,
          onOk: async () => {
            await this.onConfirm()
          },
          onCancel: () => {
            this.$emit('cancel')
          },
        })
      } else {
        this.$emit('cancel')
      }
    },
  },
}
</script>
