<template>
  <slot />
  <a-drawer
    :title="`${ action ? 'Edit' : 'Add' } Call-to-Action`"
    :width="560"
    @close="$emit('cancel')"
    :visible="visible"
  >
    <a-form
      :colon="false"
      layout="vertical"
      :model="values"
      ref="form"
      @finish="onFinish"
    >
      <a-form-item
        label="Headline"
        name="title"
        :rules="[
          { required: true, message: 'Headline is required.' },
        ]"
      >
        <a-input
          v-model:value="values.title"
          :maxlength="64"
          name="title"
        />
      </a-form-item>
      <a-form-item
        label="Description"
        name="description"
      >
        <a-textarea
          v-model:value="values.description"
          :maxlength="250"
          name="description"
          :rows="5"
          showCount
        />
      </a-form-item>
      <a-form-item
        label="Button Label and Target"
        name="label"
      >
        <a-input
          v-model:value="values.label"
          name="label"
          placeholder="Call-to-action label"
        >
          <template #prefix>
            <TagIcon class="icon text-muted" />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item
        name="url"
        :rules="[
          { type: 'url', message: 'URL is invalid.' },
        ]"
      >
        <a-input
          v-model:value="values.url"
          name="url"
          placeholder="https://example.com/funnel-page"
        >
          <template #prefix>
            <LinkIcon class="icon text-muted" />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item label="Global Position" name="position">
        <a-select
          v-model:value="values.position"
          allowClear
          name="position"
        >
          <a-select-option v-for="position in positions" :value="position.value">
            {{ position.name }}
          </a-select-option>
        </a-select>
        <small>
          <p class="text-muted mt-2">CTAs can appear on every page in the header or footer, or at the end of every article.</p>
        </small>
      </a-form-item>
      <a-form-item label="List" name="list">
        <a-select
          v-model:value="values.list"
          :loading="loadingLists"
          name="list"
          placeholder="Select list"
        >
          <a-select-option v-for="list in lists" :value="list.id">
            {{ list.provider }} ({{ list.metadata.domainName }})
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <div class="embed-responsive embed-responsive-16by9 rounded">
          <a-upload-dragger
            class="embed-responsive-item"
            name="file"
            accept="image/*"
            listType="picture"
            :customRequest="onUpload"
          >
            <div v-if="imageSource">
              <img class="rounded" style="width: 100%;" :src="imageSource">
            </div>
            <div v-else>
              <p class="ant-upload-drag-icon">
                <PhotoIcon class="text-muted w-25" />
              </p>
              <p class="ant-upload-text mb-0">Upload image</p>
              <p class="ant-upload-hint">
                Click or drag image to this area to upload
              </p>
            </div>
          </a-upload-dragger>
        </div>
      </a-form-item>
    </a-form>
    <template #extra>
      <a-button type="primary" html-type="submit" :loading="loading" @click="onFinish">
        Save
      </a-button>
    </template>
  </a-drawer>
</template>

<script>
import { LinkIcon, PhotoIcon, TagIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    LinkIcon,
    PhotoIcon,
    TagIcon,
  },

  props: {
    action: Object,
    visible: Boolean,
  },

  data() {
    return {
      lists: [],
      loading: false,
      loadingLists: false,
      uploadedImage: {
        blob: null,
        source: null,
      },
      values: {
        description: this.action && this.action.description,
        extension: this.action && this.action.extension,
        label: this.action && this.action.label,
        position: this.action && this.action.position,
        title: this.action && this.action.title,
        url: this.action && this.action.url,
      },
    }
  },

  computed: {
    imageSource() {
      return this.uploadedImage.source || this.action && this.action.image.resolutions['540']
    },
    positions() {
      return [
        { name: 'Header', value: 'header' },
        { name: 'Footer', value: 'footer' },
        { name: 'Article', value: 'article' },
      ]
    },
  },

  async mounted() {
    await this.listLists()
  },

  methods: {
    async listLists() {
      try {
        this.loadingLists = true

        var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/extensions`, {
          params: { service: 'list' }
        })

        this.lists = data.lists
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loadingLists = false
      }
    },
    async onUpload(data) {
      try {
        var reader = new FileReader()

        reader.onload = (e) => {
          this.uploadedImage.blob = data.file
          this.uploadedImage.source = e.target.result
        }
        reader.readAsDataURL(data.file)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      }

      return true
    },
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        if (this.action) {
          var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }/actions/${ this.action.id }`, values)
        } else {
          var { data } = await this.$http.post(`/publications/${ this.$store.state.publication.data.id }/actions`, values)
        }

        if (this.uploadedImage.blob) {
          var formData = new FormData()
          formData.append('file', this.uploadedImage.blob)
          var { data } = await this.$http.put(`/publications/${ this.$store.state.publication.data.id }/actions/${ data.id }`, formData)
        }

        this.$message.success('Call-to-action has been saved.')
        this.$emit('success', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
