<template>
  <div class="card h-100">
    <div v-if="action.image.hash">
      <img class="rounded-top" style="width: 100%;" :src="action.image.resolutions['540']">
    </div>
    <div v-else class="rounded-top bg-light">
      <img class="rounded-top" style="width: 100%;" src="@/assets/images/placeholder-16-9.png">
    </div>
    <div class="card-body">
      <h2 class="h6">{{ action.title }}</h2>
      <p v-if="action.description">
        {{ action.description }}
      </p>
      <p v-if="action.label" class="mb-0">
        <a-tooltip :title="action.url || 'No URL'" placement="bottom">
          <TagIcon class="icon text-muted me-2" />{{ action.label }}
        </a-tooltip>
      </p>
    </div>
    <div v-if="$store.getters['publication/isAdmin']" class="card-footer">
      <div class="d-flex justify-content-between align-items-center">
        <ul class="list-inline mb-0">
          <li class="list-inline-item small text-muted">
            Edited {{ getDateFormatted(action.updatedAt) }}
          </li>
          <li v-if="action.articlesCount" class="list-inline-item text-info">
            <a-tag>{{ action.articlesCount }} {{ action.articlesCount === 1 ? 'Article' : 'Articles' }}</a-tag>
          </li>
        </ul>
        <a-dropdown-button size="small" :trigger="['click']" @click="onClick">
          <ActionDrawer
            :action="action"
            :visible="visible.update"
            @cancel="visible.update = false"
            @success="onSuccess('update')"
          >
            Edit
          </ActionDrawer>
          <template #overlay>
            <a-menu @click="onMenuClick">
              <a-menu-item class="text-danger" key="delete">
                <ActionDelete
                  :action="action.id"
                  :visible="visible.delete"
                  @cancel="visible.delete = false"
                  @success="onSuccess('delete')"
                >
                  <TrashIcon class="icon me-2" />Delete
                </ActionDelete>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown-button>
      </div>
    </div>
  </div>
</template>

<script>
import { TagIcon, TrashIcon } from '@heroicons/vue/24/outline'
import moment from 'moment'
import ActionDrawer from '@/components/Action/ActionDrawer'
import ActionDelete from '@/components/Action/ActionDelete'

export default {
  components: {
    ActionDrawer,
    ActionDelete,
    TagIcon,
    TrashIcon,
  },

  props: {
    action: Object,
  },

  data() {
    return {
      visible: {
        delete: false,
        update: false,
      },
    }
  },

  methods: {
    getDateFormatted(date) {
      return moment(date).format('LL')
    },
    onClick() {
      this.visible.update = true
    },
    onSuccess(key) {
      this.visible[key] = false
      this.$emit('success')
    },
    onMenuClick(e) {
      this.visible[e.key] = true
    },
  },
}
</script>
