<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <h1 class="h3 mb-0">
      Call-to-Actions <small v-if="actionsCount !== null" class="text-muted">({{ actionsCount }})</small>
    </h1>
    <ActionDrawer
      v-if="$store.getters['publication/isAdmin']"
      :visible="visible"
      @cancel="visible = false"
      @success="listActions"
    >
      <a-button type="primary" shape="circle" size="large" :disabled="loading" @click="visible = true">
        <PlusIcon class="icon" />
      </a-button>
    </ActionDrawer>
  </div>
  <BaseSpinner :loading="loading">
    <div class="row">
      <div v-for="action in actions" class="col-12 col-lg-6 col-xl-4 mb-4">
        <ActionItem :action="action" @success="listActions" />
      </div>
    </div>
    <BaseEmpty v-if="actionsCount === 0">
      Create the first call-to-action for your publication.
    </BaseEmpty>
  </BaseSpinner>
  <BasePagination
    class="mt-4"
    v-model="filters.page"
    :hasMore="hasMore"
    :pageCount="Math.ceil(actionsCount / filters.limit)"
  />
</template>

<script>
import { PlusIcon } from '@heroicons/vue/24/outline'
import ActionDrawer from '@/components/Action/ActionDrawer'
import ActionItem from '@/components/Action/ActionItem'
import BaseEmpty from '@/components/Base/BaseEmpty'
import BasePagination from '@/components/Base/BasePagination'
import BaseSpinner from '@/components/Base/BaseSpinner'

export default {
  components: {
    ActionDrawer,
    ActionItem,
    BaseEmpty,
    BasePagination,
    BaseSpinner,
    PlusIcon,
  },

  data() {
    return {
      actions: [],
      actionsCount: null,
      filters: {
        limit: 20,
        page: Number(this.$route.query.page) || 1,
      },
      hasMore: false,
      loading: false,
      visible: false,
    }
  },

  async mounted() {
    await this.listActions()
  },

  methods: {
    async listActions() {
      try {
        this.loading = true
        this.visible = false

        var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/actions`, {
          params: this.filters,
        })

        this.actions = data.actions
        this.actionsCount = data.actionsCount
        this.hasMore = data.hasMore
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loading = false
      }
    },
  },

  watch: {
    'filters.page'(value) {
      this.$router.push({ name: 'publications-id-actions', query: { ...this.$route.query, page: value }})
    },
  },
}
</script>
